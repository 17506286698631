import React, {useState, useEffect} from 'react';
import {
    Button,
    Label,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Modal,
    Card,
    CardHeader,
    Row,
    Col,
    CardBody,
    Input,
    Nav,
    NavItem,
    NavLink, TabContent, TabPane
} from "reactstrap";
import UsersTable from "../DataTables/UsersTable";
import {SearchBar} from "../Framework/SearchBar";
import ManagedCompaniesTable from "../../components/DataTables/ManagedCompaniesTable";
import * as Util from "../Framework/Util";
import * as ServerResponseHandler from "../Framework/ServerResponseHandler";
import FBDatePicker from "../DateTimePickers/FBDatePicker";
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { toast } from 'react-toastify';

function CompanyModal(props) {

    let userGlobal = Util.getUserGlobal();
    const [loading, setLoading] = useState(false);
    const [company, setCompany] = useState({...props.company});
    const [activeTab, setActiveTab] = useState('companyDetails');
    const [companyUsers, setCompanyUsers] = useState([]);
    const [companyAddress, setCompanyAddress] = useState({});
    const [saved, setSaved] = useState(false);
    const [isModalTerminatorOpen, setIsModalTerminatorOpen] = useState(false);

    useEffect(loadData, [props.managedCompanies]);
    useEffect(updateCompany, [props.company]);
    useEffect(() =>{setActiveTab('companyDetails')}, [props.isOpen]);
    useEffect(() => {
            if(company.canAdministrate !== undefined &&
                (saved || company.canAdministrate != props.company.canAdministrate)) {
                setSaved(true);
                save(true);
            }
        },
        [company.canAdministrate]);

    const tableColumns = [
        {displayName: 'First Name', hideColumn: false},
        {displayName: 'Last Name', hideColumn: false},
        {displayName: 'Admin', hideColumn: false},
        {displayName: 'Manager', hideColumn: false},
        {displayName: 'Default Contact', hideColumn: false},
        {displayName: 'Password', hideColumn: false},
        {displayName: 'Login', hideColumn: false},
    ];

    const companyTableColumns = [
        {displayName: 'Company', hideColumn: false},
        {displayName: 'Email', hideColumn: false},
        {displayName: 'Status', hideColumn: false},
        {displayName: 'Active', hideColumn: false},
        {displayName: 'Delete', hideColumn: false},
    ];

    const csvProperties = {
        csvHeader: [
            {label: 'Company', key: 'companyName'},
            {label: 'Email', key: 'email'},
            {label: 'Status', key: 'status'},
            {label: 'Active', key: 'active'},
        ],
        data: props.managedCompanies,
        csvFilename: 'managedCompanies.csv',
        loading: loading
    };

    const buttons = {
        download: csvProperties,
        savePdf: false,
        loading: loading,
        add: props.toggleAddCompanyModal
    };

    function updateCompany() {
        if(props.company && props.company.id) {
            updateDbVersion(props.company.id, () => {
                getActiveCompanyUsers(props.company.id);
                getCompanyDetails(props.company.id);
            });
        }
    }

    function closeModalAfterDeletion() {
        props.closeCompanyDialog();
    }

    function save(skipCompanyDialog) {
        props.save(company, skipCompanyDialog);
    }

    function loadData() {
        setCompany({...props.company});
    }

    function updateDbVersion(companyId, callback) {
        const apiUrl = Util.apiUrl(`company_global/${companyId}/update_db`);
        fetch(apiUrl, {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json) {
                    if (json.errorMessage) {
                        toast.error(json.errorMessage, {position: 'bottom-right'});
                        return;
                    }

                    callback();
                }
            })
            .catch(error => ServerResponseHandler.handleError(error))
    }

    function resetPassword(user) {
        const formUrl = Util.apiUrl(`/register/reset_password`);
        let payload = {
            username: user.username,
        };

        fetch(formUrl, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json) {
                    if (json.errorMessage) {
                        toast.error(json.errorMessage, {position: 'bottom-right'});
                        return;
                    }
                    toast.success('Reset password email sent for ' + user.username + '.', {position: 'bottom-right'});
                }
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
    }

    function toggle(tab) {
        setActiveTab(tab);
    }

    function loginAs(e, employee) {
        const formUrl = Util.apiUrl(`switch_user/j_spring_security_check`);
        let payload = {
            username: employee.username,
        };
        let querystring = require('querystring');
        fetch(formUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: querystring.stringify(payload),
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json) {
                    if (json.errorMessage) {
                        toast.error(json.errorMessage, {position: 'bottom-right'});
                        return;
                    }

                    Util.storeSession(json);
                    window.location.href = "/dashboard";
                }
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
    }

    function handleAdministratorChange(e) {
        let _company = {...company};
        _company.canAdministrate = e.target.checked;
        setCompany(_company);
    }

    function handleMaxUsersChange(e) {
        let _company = {...company};
        _company.maxUsers = e.target.value;
        setCompany(_company);
    }

    function handleTrialExpirationChange(date) {
        let _company = {...company};
        _company.expirationDate = date;
        setCompany(_company);
    }

    const deleteDialog = {
        header: 'Confirm Deletion',
        warning: 'This action cannot be undone!',
        message: [
            `Are you sure you want to delete the Company '${company.name}'?`
        ],
        confirmText: 'Yes, Delete',
        confirm: () => {
            deleteCompany(company.id);
        },
        cancel: () => {
            setIsModalTerminatorOpen(false);
        }
    };

    const toggleTerminatorModal = () => setIsModalTerminatorOpen(!isModalTerminatorOpen);

    function deleteCompany(companyId) {
        const listCompany = [companyId]
        setLoading(true);
        const apiUrl = Util.apiUrl(`company_global/${listCompany}`);
        fetch(apiUrl, {
            method: 'DELETE',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'}
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                toast.success('The company was deleted with success!', {position: 'bottom-right'});
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => {
                setLoading(false);
                setIsModalTerminatorOpen(false);
                closeModalAfterDeletion();
            });
    }

    const handleUserDeleteAction = (userId) => {
        const updatedUsers = companyUsers.filter(user => user.id !== userId);
        setCompanyUsers(updatedUsers);
      };


    function terminatorDeleteUser(uuid) {
        setLoading(true);
        const formUrl = Util.apiUrl(`users/${uuid}`);
        fetch(formUrl, {
            method: 'DELETE',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'},
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json && json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }
                handleUserDeleteAction(uuid);
                toast.success("User was deleted with success.", {position: 'bottom-right'});
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => {
                setLoading(false);
            }
           );
    }



    function getCompanyDetails(companyId) {
        const apiUrl = Util.apiUrl(`company_global/company_details/${companyId}`);
        fetch(apiUrl, {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json) {
                    if (json.errorMessage) {
                        toast.error(json.errorMessage, {position: 'bottom-right'});
                        return;
                    }
                    if(json.mainPhone) {
                        json.mainPhone = Util.phoneNumberFormatter(json.mainPhone);
                    }
                    setCompanyAddress(json);
                }
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
    }

    function updateDbVersion(companyId, callback) {
        const apiUrl = Util.apiUrl(`company_global/${companyId}/update_db`);
        fetch(apiUrl, {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json) {
                    if (json.errorMessage) {
                        toast.error(json.errorMessage, {position: 'bottom-right'});
                        return;
                    }

                    callback();
                }
            })
            .catch(error => ServerResponseHandler.handleError(error))
    }

    function getActiveCompanyUsers(companyId) {
        setLoading(true);
        const formUrl = Util.apiUrl(`user_global/all_users_in_company/${companyId}`);
        fetch(formUrl, {
            method: 'GET',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'}
        })
            .then(response => ServerResponseHandler.getResponse(response))
            .then(json => {
                if (json && json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }
                setCompanyUsers(json);
            })
            .catch(error => ServerResponseHandler.handleError(error))
            .finally(() => setLoading(false));
    }

    const clearUsersData = () => {
        setCompanyUsers([]);
    };

    return (
        <Modal isOpen={props.isOpen} className={'modal-lg'}>
            <ModalHeader toggle={props.toggleEditModal}>{props.company.name}</ModalHeader>
            <ModalBody id="modal-form">
                <div className="h-100">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                active={activeTab === 'companyDetails'}
                                onClick={() => { toggle('companyDetails'); }}
                            >
                                Company Details
                            </NavLink>
                        </NavItem>
                        {
                            Util.isGlobalAdmin() &&
                                <NavItem>
                                    <NavLink
                                        disabled={!company.canAdministrate}
                                        active={activeTab === 'managedCompanies'}
                                        onClick={() => {
                                            toggle('managedCompanies');
                                        }}
                                    >
                                        Managed Companies
                                    </NavLink>
                                </NavItem>
                        }
                    </Nav>
                    <TabContent activeTab={activeTab} style={{height: 'calc(100% - 38px)'}}>
                        <TabPane tabId="companyDetails">
                            <Row>
                                <Col style={{maxWidth: '35%'}}>
                                    <Card className="card-accent-primary">
                                        <CardHeader>Company Details</CardHeader>
                                        <CardBody>
                                            <div className="mb-3">
                                                <div className="no-margin">{company.name}</div>
                                                <div className="address no-margin">{companyAddress.street1}</div>
                                                {
                                                    !companyAddress.street2 ? '' :
                                                    <div className="address no-margin" >{companyAddress.street2}</div>
                                                }
                                                {
                                                    !companyAddress.street3 ? '' :
                                                        <div className="address no-margin" >{companyAddress.street3}</div>
                                                }
                                                {
                                                    !companyAddress.street4 ? '' :
                                                        <div className="address no-margin" >{companyAddress.street4}</div>
                                                }
                                                <div className="address no-margin"><span>{companyAddress.city ? companyAddress.city +  ', ' : ' '}</span><span>{companyAddress.state + ' ' + companyAddress.zip}</span></div>
                                                <div className="address no-margin">{companyAddress.mainPhone}</div>
                                            </div>
                                            {
                                                !userGlobal.executor ?
                                                    <div>
                                                        <div className="no-margin">Trial Expiration</div>
                                                        <div className="address no-margin" >
                                                            {Util.basicDateFormatter(company.expirationDate)}
                                                        </div>
                                                    </div>
                                                    :
                                                    <div>
                                                        <Row style={{marginBottom: '15px'}}>
                                                            <Label>Trial Expiration</Label>
                                                            <FBDatePicker
                                                                id="expiration"
                                                                className="form-control"
                                                                value={company.expirationDate}
                                                                disabled={!Util.isGlobalAdmin()}
                                                                onChange={handleTrialExpirationChange}
                                                            />
                                                        </Row>
                                                        <Label>Administrator</Label>
                                                        <Label
                                                            className="switch switch-icon switch-pill switch-info pull-right mb-3">
                                                            <Input type="checkbox" name="Active"
                                                                   className="switch-input"
                                                                   checked={company.canAdministrate}
                                                                   onChange={handleAdministratorChange}/>
                                                            <span className="switch-label" data-on={'\uf00c'}
                                                                  data-off={'\uf00d'}/>
                                                            <span className="switch-handle"/>
                                                        </Label>
                                                        <div style={{marginTop: "8px"}}>
                                                            <Label>Max Users</Label>
                                                                    <Input type="number" min="1" placeholder="" name="maxUsers"
                                                                        value={company.maxUsers}
                                                                        disabled={!company.onTrial && !Util.isGlobalAdmin()}
                                                                        className="pull-right mb-3 form-control"
                                                                        onChange={handleMaxUsersChange}
                                                                        style={{width: '80px', marginTop: '-6px'}}/>
                                                        </div>
                                                    </div>
                                            }
                                            <div className="no-margin">Stripe Customer ID: {company.stripeCustomerId}</div>
                                            <div className="no-margin">Stripe Subscription ID: {company.stripeSubscriptionId}</div>
                                            {
                                                userGlobal.executor && userGlobal.terminator ?
                                                <div>
                                                    <div style={{marginTop: "10px", textAlign: "left"}}>
                                                        <Button color="danger" onClick={toggleTerminatorModal}>Delete</Button>
                                                    </div>

                                                    <ConfirmDeleteModal
                                                        isOpen={isModalTerminatorOpen}
                                                        deleteDialog={deleteDialog}
                                                    />
                                                    </div>
                                                :
                                                ''
                                            }
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card className="card-accent-primary">
                                    <div>
                                    {companyUsers.length < 1 ? (
                                        <div>
                                           <Row className="row-header col-header col-lg-12">
                                            {
                                                tableColumns.map((column, index) => {
                                                    return column.hideColumn ? '' :
                                                        <Col key={index}>{column.displayName}</Col>
                                                })
                                            }
                                            { userGlobal.terminator && <Col>Delete</Col> }
                                        </Row>
                                            <div className="p-2 no-data"><h1 className="text-center">No Data</h1></div>
                                        </div>
                                    ) : (
                                        <UsersTable resetPassword={resetPassword} tableColumns={tableColumns} loginAs={loginAs} data={companyUsers}
                                        userGlobal={userGlobal} terminatorDeleteUser={terminatorDeleteUser} clearData={clearUsersData} />
                                    )}
                                    </div>
                                  
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                        {Util.isGlobalAdmin() &&
                        <TabPane tabId="managedCompanies">
                            <Card className="col-lg-12 mt-3 card-accent-primary">
                                <CardBody>
                                    <SearchBar
                                        buttons={buttons}
                                        onSearch={loadData}
                                        includeActiveFlag={true}
                                        // getSearchBarApi={setSearchBarApi}
                                    />
                                    <ManagedCompaniesTable
                                        data={props.managedCompanies}
                                        tableColumns={companyTableColumns}
                                        toggleConfirmDeleteModal={props.toggleConfirmDeleteModal}
                                        // toggleModal={props.toggleAddCompanyModal}
                                        tableSettings={{
                                            canEdit: true
                                        }}
                                    />
                                </CardBody>
                            </Card>
                        </TabPane>}
                    </TabContent>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={() => {save(false);}}>Save</Button>{' '}
                <Button color="secondary" onClick={props.toggleEditModal}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}

function useFormInput(initialValue) {
    const [form, setForm] = useState(initialValue);

    function handleChange(e) {
        let newFormObj = Object.assign({}, form);
        newFormObj[e.target.name] = Math.floor(e.target.value);
        setForm(newFormObj);
    }

    return [
        form,
        handleChange
    ]
}

export default CompanyModal;