import React, {Component} from 'react';
import {Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input} from 'reactstrap';
import NoData from "./NoData";
import _ from "lodash";

class UsersTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lastOrderSelected: '',
            userData: [],
            confirmResetPasswordDialog: false,
            currentRow: '',
            currentData: [],
            showInactiveUsers: false
        }
    }
    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.data.length > 0 && this.props.data !== this.state.currentData) this.loadData();
    }

    componentWillUnmount() {
        if (this.props.clearData) {
            this.props.clearData();
        }
    }

    loadData = (columnHeader) => {
        if (columnHeader) {
            switch (columnHeader) {
                case 'First Name':
                    columnHeader = 'nameFirst';
                    break;
                case 'Last Name':
                    columnHeader = 'nameLast';
                    break;
                case 'Default Contact':
                    columnHeader = 'defaultContact';
                    break;
                default:
                    columnHeader = columnHeader.toLowerCase();
            }

            let tmpUserData = this.filterEmployeeList(this.state.showInactiveUsers);

            if (this.props.data && columnHeader !== this.state.lastOrderSelected) {
                switch (columnHeader) {
                    case 'admin':
                    case 'manager':
                    case 'defaultContact':
                        this.setState({userData:_.sortBy(tmpUserData, columnHeader).reverse()});
                        break;
                    case 'nameFirst':
                        this.setState({userData: _.sortBy(tmpUserData, (d) => d.nameFirst.toLowerCase())});
                        break;
                    case 'nameLast':
                        this.setState({userData: _.sortBy(tmpUserData, (d) => d.nameLast.toLowerCase())});
                        break;
                    default:
                        this.setState({userData: _.sortBy(tmpUserData, columnHeader)});
                }
                this.setState({lastOrderSelected: columnHeader});
            } else {
                switch (columnHeader) {
                    case 'admin':
                    case 'manager':
                    case 'defaultContact':
                        this.setState({userData:_.sortBy(tmpUserData, columnHeader)});
                        break;
                    case 'nameFirst':
                        this.setState({userData: _.sortBy(tmpUserData, (d) => d.nameFirst.toLowerCase()).reverse()});
                        break;
                    case 'nameLast':
                        this.setState({userData: _.sortBy(tmpUserData, (d) => d.nameLast.toLowerCase()).reverse()});
                        break;
                    default:
                        this.setState({userData: _.sortBy(tmpUserData, columnHeader).reverse()});
                }
                this.setState({lastOrderSelected: ''});
            }
        } else {
            this.setState({userData:_.sortBy(this.props.data, 'admin').reverse()});
            this.setState({lastOrderSelected: 'admin'});
        }

        let userList = this.filterEmployeeList(this.state.showInactiveUsers);
        if(userList.length !== this.state.userData.length) this.setState({userData: userList});
        this.setState({currentData: this.props.data});
    }

    filterEmployeeList = (toggleValue) => {
        if(toggleValue === true) return this.props.data;

        let filteredUserList = [];
        for (const user of this.props.data) {
            if (user.active) filteredUserList.push(user);
        }
        return filteredUserList;
    }

    toggleConfirmPasswordResetModal= () => {
        this.setState({confirmResetPasswordDialog: !this.state.confirmResetPasswordDialog});
    }

    confirmResetPassword = (row) =>{
        this.setState({currentRow: row});
        this.toggleConfirmPasswordResetModal();
    }

    toggleInactiveUsers = () => {
        let userData = this.filterEmployeeList(!this.state.showInactiveUsers);
        this.setState({userData: userData, showInactiveUsers: !this.state.showInactiveUsers});
    }

    toggleDeleteUserModal = () => {
        this.setState({confirmDeleteUserDialog: !this.state.confirmDeleteUserDialog});
    }

    confirmDeleteUser = (row) => {
        this.setState({ currentRow: row });
        this.toggleDeleteUserModal();
    }

    handleDeleteUser = () => {
        const { currentRow } = this.state;
        this.props.terminatorDeleteUser(currentRow.id);
        this.toggleDeleteUserModal();
    }    

    render() {
        const isTerminator = this.props.userGlobal && this.props.userGlobal.terminator;
        const isExecutor = this.props.userGlobal && this.props.userGlobal.executor;
        const { currentRow } = this.state;

        return (
            <div>
                <Label className="switch switch-icon switch-pill switch-info pull-right mb-3 mt-3 mr-3">
                    <Input type="checkbox" name="Active"
                           className="switch-input"
                           checked={this.state.showInactiveUsers}
                           onChange={this.toggleInactiveUsers}/>
                    <span className="switch-label" data-on={'\uf00c'}
                          data-off={'\uf00d'}/>
                    <span className="switch-handle"/>
                </Label>
                <Label className="pull-right mr-3 mt-3">Show Inactive Users</Label>

                <Row className="row-header col-header col-lg-12">
                    {
                        this.props.tableColumns.map((column, index) => {
                            return column.hideColumn ? '' :
                                <Col key={index} onClick={() => this.loadData(column.displayName)}>{column.displayName}</Col>
                        })
                    }
                    { isTerminator && <Col>Delete</Col> }
                </Row>
                {
                    this.state.userData.length < 1 ?
                        <NoData/> :
                        this.state.userData.map((row, index) => {
                            return <div className='data-row margin-top' key={index}>
                                <Modal isOpen={this.state.confirmResetPasswordDialog}>
                                    <ModalHeader>Send Reset Password Email</ModalHeader>
                                    <ModalBody>
                                        This didn&#39;t used to have to be here, but enough people pushed this button on
                                        accident and confused our customers, so here we are. <br/>
                                        Are you <b>positive</b> that you want to send this reset password email?
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="success" onClick={() => {this.props.resetPassword(this.state.currentRow); this.toggleConfirmPasswordResetModal();}}>Yes, I
                                            do</Button>{' '}
                                        <Button color="secondary"
                                                onClick={() => this.toggleConfirmPasswordResetModal()}>No. I&#39;m sorry.</Button>
                                    </ModalFooter>
                                </Modal>
                                <Row className='row-data row-header col-lg-12'>
                                    <Col><span title={row.nameFirst}>{row.nameFirst}</span></Col>
                                    <Col><span title={row.nameLast}>{row.nameLast}</span></Col>
                                    <Col><span>{row.admin ? 'Yes' : 'No'}</span></Col>
                                    <Col><span>{row.manager ? 'Yes' : 'No'}</span></Col>
                                    <Col><span>{row.defaultContact ? 'Yes' : 'No'}</span></Col>
                                    <Col className="cursor-text">
                                        <span title="Send Reset Password Email" onClick={() => this.confirmResetPassword(row)}
                                              className="action-icon material-icons">send</span>
                                    </Col>
                                    <Col className="cursor-text">
                                        {
                                            !row.active ? '' :
                                            <span title="Login" onClick={e => {
                                                this.props.loginAs(e, row)
                                            }} className="action-icon material-icons">how_to_reg</span>
                                        }
                                    </Col>
                                    { row.active && isExecutor && isTerminator ? 
                                            <Col className="cursor-text">
                                            {
                                                <span title="Delete" onClick={() =>  this.confirmDeleteUser(row)}
                                                    className="action-icon material-icons">delete</span>
                                            }
                                        </Col>
                                        : ''
                                    }
                                </Row>
                                <Modal isOpen={this.state.confirmDeleteUserDialog}>
                                    <ModalHeader>Confirm Delete</ModalHeader>
                                    <ModalBody>
                                        Are you sure you want to delete this user: {currentRow.nameFirst} {currentRow.nameLast}?
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="success" onClick={this.handleDeleteUser}>Yes, Delete</Button>
                                        <Button color="secondary" onClick={this.toggleDeleteUserModal}>Cancel</Button>
                                    </ModalFooter>
                                </Modal>
                            </div>
                        })
                }
            </div>
        )
    }
}

export default UsersTable;
