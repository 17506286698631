import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, Row} from 'reactstrap';
import * as ServerResponseHandler from "../../../components/Framework/ServerResponseHandler";
import * as Util from '../../../components/Framework/Util';
import * as _ from 'lodash';
import {useLocation} from "react-router";
import {toast, ToastContainer} from 'react-toastify';


function Login() {
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showMaintenanceBanner, setShowMaintenanceBanner] = useState(false);
    const [maintenanceMessage, setMaintenanceMessage] = useState('');
    const [retrievedMaintenance, setRetrievedMaintenance] = useState(false);

    let loginLoading = false;
    const location = useLocation();
    const submit = _.debounce(handleSubmit, 1000, {leading: true, trailing: false});
    const staticBanner = (message) => {
        return (
            <div className="static-banner"
                 style={{
                     position: 'fixed',
                     top: '50px',
                     left: '0px',
                     height: '50px',
                     width: '100%',
                     color: 'white',
                     fontSize: 'large',
                     backgroundColor: 'blue',
                     padding: '10px',
                     zIndex: 999,  // Ensure it stays below the server-driven banner but above other elements
                     textAlign: 'center'
                 }}
            >
                {message}
            </div>
        );
    };
    const banner = (message) => {
        return (
            <div className="maintenance-banner"
                 style={{
                     position: 'fixed',
                     top: '0px',
                     left: '0px',
                     height: '50px',
                     width: '100%',
                     color: 'white',
                     fontSize: 'large',
                     backgroundColor: 'red',
                     padding: '10px',
                     zIndex: 1000, // in the event this is on at the same time as the static banner, they won't overlap.
                     textAlign: 'center'
                 }}
            >
                {message}
            </div>
        );
    };

    useEffect(() => {
        if (!retrievedMaintenance) getMaintenanceMessage()
    });

    function getMaintenanceMessage() {
        setRetrievedMaintenance(true);
        const apiUrl = Util.apiUrl(`maintenance/activeMessages`);
        fetch(apiUrl, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {

                if (response.status === 504) {
                    setMaintenanceMessage(banner("We are currently experiencing issues connecting to our server. Please wait and try again in a few minutes."));
                    setShowMaintenanceBanner(true);
                    throw new Error("Unable to connect to server");
                } else
                    ServerResponseHandler.getResponse(response)
                        .then(json => {
                            if (json.errorMessage) {
                                toast.error(json.errorMessage, {position: 'bottom-right'});
                                return;
                            }
                            if (json.length > 0) {
                                setMaintenanceMessage(banner(json[0].message));
                                setShowMaintenanceBanner(true);
                            }
                        })
                        .catch(error => ServerResponseHandler.handleError(error))
            })
    }

    function handleUsernameChange(e) {
        setUsername(e.target.value);
    }

    function handlePasswordChange(e) {
        setPassword(e.target.value);
    }

    function registerButton(e) {
        window.location.href = '/register';
    }

    function passwordButton(e) {
        window.location.href = '/reset_password';
    }

    function handleSubmit(event) {
        event.preventDefault();
        if (loginLoading) {
            return;
        }
        setLoading(true);
        loginLoading = true;
        let payload = {
            username: username,
            password: password
        };
        let querystring = require('querystring');

        const apiUrl = Util.apiUrl(`resources/login`);
        fetch(apiUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            body: querystring.stringify(payload),
        }).then(response => ServerResponseHandler.getResponse(response)
        ).then(json => {
            if (json) {
                if (json.errorMessage) {
                    toast.error(json.errorMessage, {position: 'bottom-right'});
                    return;
                }

                Util.storeSession(json);

                if (location.state && location.state.from) {
                    window.location.href = location.state.from;
                } else {
                    if (Util.isManager()) {
                        window.location.href = "/dashboard";
                    } else {
                        window.location.href = "/timeclock/my";
                    }
                }
            }
        }).catch(error => {
            toast.error("Login error, please double check your credentials", {position: 'bottom-right'})
            loginLoading = false;
            ServerResponseHandler.handleError(error.message)
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className="app flex-row align-items-center">
            {showMaintenanceBanner ? banner(maintenanceMessage) : null}  {/* Server-driven banner */}
            {staticBanner("Fishbowl Time will be down for maintenance on Tuesday, October 8th from 6pm-11pm MT.  We apologize for any inconvenience this may cause.")} {/* Static banner */}
            <Container>
                <Row className="justify-content-center">
                    <Col lg="8">
                        <CardGroup>
                            <Card className="p-4">
                                <CardBody className="text-center">
                                    <form id="login-form" onSubmit={submit}>
                                        <img style={{width: '100%'}}
                                             src="https://s3.amazonaws.com/fishbowllabor/StaticContent/fbtimelogo.png"/>
                                        <p>&nbsp;</p>
                                        <InputGroup className="mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <span className="material-icons">person</span>
                                                </span>
                                            </div>
                                            <Input id="username" type="text" placeholder="Username" required
                                                   onChange={handleUsernameChange}
                                                   autoComplete="username"
                                                   disabled={showMaintenanceBanner}
                                            />
                                        </InputGroup>
                                        <InputGroup className="mb-4">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <span className="material-icons">lock</span>
                                                </span>
                                            </div>
                                            <Input id="password" type="password" placeholder="Password" required
                                                   onChange={handlePasswordChange}
                                                   autoComplete="current-password"
                                                   disabled={showMaintenanceBanner}
                                            />
                                        </InputGroup>
                                        <Row>
                                            <Col xs="6">
                                                <Button type="submit" id="login" color="primary"
                                                        disabled={loading || loginLoading || showMaintenanceBanner}
                                                        className="px-4">
                                                    {loading
                                                        ? <i className="fa fa-spinner fa-spin mr-2"></i>
                                                        : ''}
                                                    Login
                                                </Button>
                                            </Col>
                                            <Col xs="6" className="text-right">
                                                <Button color="link"
                                                        className="px-0"
                                                        onClick={passwordButton}
                                                        disabled={showMaintenanceBanner || loading}
                                                >Forgot password?</Button>
                                            </Col>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                            <Card className="text-white bg-secondary py-5">
                                <CardBody className="text-center">
                                    <div>
                                        <h2>Sign up</h2>
                                        <Button color="primary" className="mt-3" active
                                                disabled={loading || showMaintenanceBanner} onClick={registerButton}>Register
                                            Now!</Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </CardGroup>
                    </Col>
                </Row>
            </Container>
            <ToastContainer/>
        </div>
    );
}

export default Login;