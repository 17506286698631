import config from "react-global-configuration";
import { ToastContainer, toast } from 'react-toastify';
import * as ServerResponseHandler from "./ServerResponseHandler";

const moment = require('moment-timezone');

export function storeSession(data) {
    localStorage.setItem("userGlobal", JSON.stringify(data.UserGlobal));
    localStorage.setItem("userTenant", JSON.stringify(data.UserTenant));
    localStorage.setItem("companyTenant", JSON.stringify(data.CompanyTenant));
    localStorage.setItem("companySettings", JSON.stringify(data.CompanySettings));
    localStorage.setItem("currencyCode", data.currencyCode);
    localStorage.setItem("isExecutor", data.isExecutor);
    localStorage.setItem("loggedWithExecutor", data.loggedWithExecutor);
    localStorage.setItem("isGlobalAdmin", data.isGlobalAdmin);
    localStorage.setItem("isAdmin", data.isAdmin);
    localStorage.setItem("isManager", data.isManager);
    localStorage.setItem("supportLevel", data.supportLevel);
    localStorage.setItem("loggedIn", JSON.stringify(true));
    localStorage.setItem("Environment", data.Environment);
    localStorage.setItem("accountExpired", data.accountExpired);
    localStorage.setItem("maxUsers", data.UserGlobal.company.maxUsers);
    localStorage.setItem("isTerminator", data.isTerminator);
    
    if (data.Environment.toLowerCase() === 'prod' || data.Environment.toLowerCase() === 'production') {
        localStorage.setItem("stripeKey", 'pk_live_G6AqvPtePBwbSzmHclF5SRzi');
    } else {
        localStorage.setItem("stripeKey", 'pk_test_FFqmGuYpihkgUdTmCQVbOjWG');
    }
}

export function getUserGlobal() {
    let userGlobal = {
        company: {
            fishbowlIntegrated: false
        }
    };
    if (localStorage.getItem("userGlobal") !== null && typeof localStorage.getItem("userGlobal") !== "undefined") {
        userGlobal = JSON.parse(localStorage.getItem("userGlobal"));
    }
    return userGlobal;
}

export function getLoggedWithExecutor() {
    let loggedWithExecutor = false;
    if (localStorage.getItem("loggedWithExecutor") !== null && typeof localStorage.getItem("loggedWithExecutor") !== "undefined") {
        loggedWithExecutor = JSON.parse(localStorage.getItem("loggedWithExecutor"));
    }
    return loggedWithExecutor;
}

export function setUserGlobal(userGlobal) {
    localStorage.setItem("userGlobal", JSON.stringify(userGlobal));
}

export function sanitizeData(data) {
    data.map((line) => {
        for (let prop in line) {
            if (line[prop] === null) {
                line[prop] = undefined;
            }
        }
    });
    return data;
}

export function getUserTenant() {
    let userTenant = {};
    if (localStorage.getItem("userTenant") !== null) {
        try {
            userTenant = JSON.parse(localStorage.getItem("userTenant"));
        } catch(e) {
            console.log(e);
        }
    }
    return userTenant;
}

export function getCompanySettings() {
    let companySettings = {};
    if (localStorage.getItem("companySettings") !== null) {
        try {
            companySettings = JSON.parse(localStorage.getItem("companySettings"));
        } catch(e) {
            console.log(e);
        }
    }
    return companySettings;
}

export function getCompanyTenant() {
    let companyTenant = {};
    if (localStorage.getItem("companyTenant") !== null) {
        try {
            companyTenant = JSON.parse(localStorage.getItem("companyTenant"));
        } catch(e) {
            console.log(e);
        }
    }
    return companyTenant;
}

export function getCurrentTimeLog() {
    let currentTimeLog = null;
    if (localStorage.getItem("currentTimeLog") !== null) {
        try {
            currentTimeLog = JSON.parse(localStorage.getItem("currentTimeLog"));
        } catch(e) {
            console.log(e);
        }
    }
    return currentTimeLog;
}

export function phoneNumberFormatter(phoneNumberString) {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        let intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return null
}

export function nameFormatter(nameFirst, nameLast) {
    const companySettings = getCompanySettings();
    if (companySettings === null || companySettings.nameFormat === 'Firstname Lastname') {
        return `${nameFirst} ${nameLast}`;
    } else {
        return `${nameLast} ${nameFirst}`;
    }
}

export function isAdmin() {
    return JSON.parse(localStorage.getItem("isAdmin"));
}

export function isExecutor() {
    return JSON.parse(localStorage.getItem("isExecutor"));
}

export function isGlobalAdmin() {
    return JSON.parse(localStorage.getItem("isGlobalAdmin"));
}

export function isManager() {
    return isAdmin() || JSON.parse(localStorage.getItem("isManager"));
}

export function isNull(object) {
    return object === undefined || object === null;
}

export function isEmpty(object) {
    return isNull(object) || object === '';
}

// Takes a date from an HTML <input type="date" />
export function formatDateToUtc(dateValue) {
    if (isEmpty(dateValue)) {
        return '';
    }

    const zonedTime = moment.tz(dateValue, moment.tz.guess()).format();
    return moment.tz(zonedTime, 'ETC/UTC').utc().format();
}

// Takes a UTC ZonedDateTime
export function basicDateFormatter(dateValue, showHours = false, toLocalTZ = true) {
    if (isEmpty(dateValue)) {
        return '';
    }

    const companySettings = getCompanySettings();

    if (showHours) {
        return companySettings.useInternationalDates
            ? toLocalTZ ? moment.tz(dateValue, moment.tz.guess()).format('DD/MM/YYYY h:mm a') : moment(dateValue).utc().format('DD/MM/YYYY h:mm a')
            : toLocalTZ ? moment.tz(dateValue, moment.tz.guess()).format('MM/DD/YYYY h:mm a')  : moment(dateValue).utc().format('MM/DD/YYYY h:mm a')
    } else {
        return companySettings.useInternationalDates
            ? toLocalTZ ? moment.tz(dateValue, moment.tz.guess()).format('DD/MM/YYYY') : moment(dateValue).utc().format('DD/MM/YYYY')
            : toLocalTZ ? moment.tz(dateValue, moment.tz.guess()).format('MM/DD/YYYY') : moment(dateValue).utc().format('MM/DD/YYYY');
    }
}

// Takes a UTC ZonedDateTime
export function formatDateFromUtc(dateValue, showHours = true) {
    if (isEmpty(dateValue)) {
        return '';
    }

    if (showHours) {
        return moment.tz(dateValue, moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss');
    } else {
        return moment.tz(dateValue, 'YYYY-MM-DD', moment.tz.guess()).format('YYYY-MM-DD');
    }
}

export function ParseTimeFromDateTime(dateValue) {
    let newDate = moment(dateValue, "YYYY-MM-DD hh:mm A");
    return newDate.format('LT');
}

export function multiDateFormat(line, clockInDate, clockOutDate) {
    let today = basicDateFormatter(moment(new Date()));
    let date = clockInDate;
    if (clockOutDate) {
        if (clockInDate !== clockOutDate) {
            date = clockInDate + ('-' + clockOutDate)
        }
    } else if (clockInDate !== today) {
        date += (' - Present');
    }
    return date;
}

export function getTimeStamp() {
    let timeStamp = moment().format('MM/DD/YYYY, h:mm:ss a');
    return timeStamp;
}

export function getDurationInSeconds(shiftClockIn, shiftClockOut) {
    let clockIn = moment(shiftClockIn);
    let clockOut = moment(shiftClockOut);
    return moment.duration(moment(clockOut).diff(clockIn, 'seconds'), 'seconds').asSeconds();
}

export function combineBreakDurations(shiftBreaks) {
    let duration = 0;
    shiftBreaks.forEach((shiftBreak) => {
        duration += Number(shiftBreak.duration);
    });
    return duration;
}

export function formatTimeFromSecondsMorePrecision(sec_num) {
    let hours = (sec_num / 3600).toPrecision(5);
    let timeString = hours.split('.');
    let minutes = ('.' + timeString[1]) * 60;

    const companySettings = getCompanySettings();
    if (companySettings == null || companySettings.timeFormat === 'HH:MM') {
        if (hours < 10) {
            hours = "0" + parseFloat(hours[0]).toFixed(0);
}
        else {
            hours = parseFloat(hours[0]).toFixed(0)
        }
        if (minutes < 10) {
            minutes = "0" + parseFloat(minutes).toFixed(0);
        } else {
            minutes = parseFloat(minutes).toFixed(0)
        }
        return hours + ':' + minutes;
    } else {
        return hoursFromSecondsMorePrecision(sec_num)
    }
}

export function hoursFromSecondsMorePrecision(sec_num) {
    let hours = sec_num / 3600;
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);

    hours += minutes / 60;
    return hours.toFixed(3);
}

export function formatTimeFromSeconds(sec_num) {
    let hours = sec_num >= 0 ? Math.floor(sec_num / 3600) : 0;
    let minutes = sec_num >= 0 ? Math.floor((sec_num - (hours * 3600)) / 60) : 0;

    const companySettings = getCompanySettings();
    if (companySettings == null || companySettings.timeFormat === 'HH:MM') {
        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        return hours + ':' + minutes;
    } else {
        return hoursFromSeconds(sec_num) + ' hrs';
    }
}

export function formatTimer(sec_num, showSeconds) {
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);
    if (hours < 10) {
        hours = "0" + hours;
    }
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    return showSeconds ? hours + ':' + minutes + ':' + seconds : hours + ':' + minutes;
}

export function hoursFromSeconds(sec_num) {
    let decimal = sec_num / 3600;

    return decimal.toFixed(2);
}

/*
 * Taken from https://gist.github.com/icemilo/a0b98a1508aab82853eb
 */
export function calculateBusinessDays(firstDate, secondDate) {
    //Initiallize variables
    let day1 = firstDate
    let day2 = secondDate;
    let adjust = 0;

    //Check if second date is before first date to switch
    if (day2.isBefore(day1)) {
        day2 = firstDate;
        day1 = secondDate;
    }

    //Check if first date starts on weekends
    if (day1.day() === 6) { //Saturday
        //Move date to next week monday
        day1.day(8);
    } else if (day1.day() === 0) { //Sunday
        //Move date to current week monday
        day1.day(1);
    }

    //Check if second date starts on weekends
    if (day2.day() === 6) { //Saturday
        //Move date to current week friday
        day2.day(5);
    } else if (day2.day() === 0) { //Sunday
        //Move date to previous week friday
        day2.day(-2);
    }

    let day1Week = day1.week();
    let day2Week = day2.week();

    //Check if two dates are in different week of the year
    if (day1Week !== day2Week) {
        //Check if second date's year is different from first date's year
        if (day2Week < day1Week) {
            day2Week += day1Week;
        }
        //Calculate adjust value to be substracted from difference between two dates
        adjust = -2 * (day2Week - day1Week);
    }

    return day2.diff(day1, 'days') + adjust;
}

export function sortJson(field, desc, primer) {
    const key = primer ?
        function (x) {
            return primer(x[field]);
        }
        : function (x) {
            return x[field];
        }
    ;

    desc = !desc ? 1 : -1;

    return function (a, b) {
        return a = key(a), b = key(b), desc * ((a > b) - (b > a));
    }
}

export function jsonUrl(obj) {
    return encodeURIComponent(JSON.stringify(obj));
}

export function showInputError(parent, refName) {
    if (isEmpty(refName)) {
        return true;
    }
    const ref = parent.refs[refName];
    if (isNull(ref)) {
        return true;
    }
    const validity = ref.validity;
    if (isNull(validity)) {
        return true;
    }

    return validity.valid;
}

export function displayInputError(ref) {
    if (isEmpty(ref)) {
        return true;
    }
    if (isNull(ref)) {
        return true;
    }
    const validity = ref.validity;
    if (isNull(validity)) {
        return true;
    }
    return validity.valid;
}

export function apiUrl(endPoint) {
    return config.get('apiBaseUrl') + `${endPoint}`;
}

export function roundDateTo(date, duration, method = 'ceil') {
    return moment(Math[method]((+date) / (+duration)) * (+duration));
}

export function apiSearchUrl(endPoint, search = null) {
    let apiUrl = config.get('apiBaseUrl') + `${endPoint}?search=`;
    if (search === null) {
        apiUrl += encodeURIComponent(JSON.stringify(defaultSearchParam));
    } else {
        apiUrl += encodeURIComponent(JSON.stringify(search));
    }
    return apiUrl;
}

export const defaultSearchParam = {
    startDate: formatDateToUtc(moment.tz().startOf('week')),
    endDate: formatDateToUtc(moment.tz().endOf('week')),
    projectStatus: 'All',
    departmentId: isAdmin() ? null : getUserTenant().departmentId,
    active: true,
};

export const defaultDateRanges = {
    'Today': {
        startDate: (now) => {
            return now;
        },
        endDate: (now) => {
            return now;
        }
    },

    'Yesterday': {
        startDate: (now) => {
            return now.add(-1, 'days');
        },
        endDate: (now) => {
            return now.add(-1, 'days');
        }
    },

    'This Week': {
        startDate: (now) => {
            return now.startOf('week');
        },
        endDate: (now) => {
            return now.endOf('week');
        }
    },

    'This Month': {
        startDate: (now) => {
            return now.startOf('month');
        },
        endDate: (now) => {
            return now.endOf('month');
        }
    },

    'Last Week': {
        startDate: (now) => {
            return now.subtract(1, 'weeks').startOf('week');
        },
        endDate: (now) => {
            return now.subtract(1, 'weeks').endOf('week');
        }
    },

    'Last Month': {
        startDate: (now) => {
            return now.subtract(1, 'months').startOf('month');
        },
        endDate: (now) => {
            return now.subtract(1, 'months').endOf('month');
        }
    },
};

export const ptoDateRanges = {
    'Today': {
        startDate: (now) => {
            return now;
        },
        endDate: (now) => {
            return now;
        }
    },

    'Tomorrow': {
        startDate: (now) => {
            return now.add(1, 'days');
        },
        endDate: (now) => {
            return now.add(1, 'days');
        }
    },

    'Next 30 days': {
        startDate: (now) => {
            return now;
        },
        endDate: (now) => {
            return now.add(30, 'days');
        }
    },

    'This Month': {
        startDate: (now) => {
            return now.startOf('month');
        },
        endDate: (now) => {
            return now.endOf('month');
        }
    },

    'Next Week': {
        startDate: (now) => {
            return now.add(1, 'weeks').startOf('week');
        },
        endDate: (now) => {
            return now.add(1, 'weeks').endOf('week');
        }
    },

    'Next Month': {
        startDate: (now) => {
            return now.add(1, 'months').startOf('month');
        },
        endDate: (now) => {
            return now.add(1, 'months').endOf('month');
        }
    },
};

export function formatCurrency(money = 0, centify = false) {
    if (centify) {
        money = money.toFixed(2);
    }
    return '$' + money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function calculateDayOfWorkWeekSearch(startDate, weekStartDay) {
    let workWeekStartDate = moment().day(weekStartDay).startOf('day');
    while (moment(workWeekStartDate).isAfter(startDate)) {
        workWeekStartDate = moment(workWeekStartDate).subtract(7, 'days');
    }
    return workWeekStartDate;
}

export function flagShiftsExceeding(duration) {
    return getCompanySettings().flagShiftsExceeding > 0 && duration > getCompanySettings().flagShiftsExceeding;
}

export function reportNoDataAlert() {
    return toast.info("Report returned with no data", {position: 'bottom-right'});
}

export function getPayPeriods(search, payPeriodRule) {
    const endpoint = payPeriodRule ? 'reports/pay-periods/' + payPeriodRule : 'reports/pay-periods';
    return fetch(apiUrl(endpoint), {credentials: 'include'})
        .then(response => ServerResponseHandler.getResponse(response))
        .then(json => {
            if (!search) {
                return {
                    startDate: moment(json.current.startDate).startOf('day'),
                    endDate: moment(json.current.endDate).endOf('day')
                }
            }

            return search;
        });
}

export function updateUserLocalStorage(userId) {
    return fetch(apiUrl(`users/${userId}`), {credentials: 'include'})
        .then(response => ServerResponseHandler.getResponse(response))
        .then(json => {
            localStorage.setItem("userTenant", JSON.stringify(json));
        })
        .catch(error => ServerResponseHandler.handleError(error));
}

export function trimWithEllipses(text, limit, start = 0) {
    if (text.length > limit) {
        return `${text.substring(start, limit)}...`
    }

    return text;
}

export function formatEmployeeTimeDataByDate(json, projects = false) {
    let dates = {};
    let today = basicDateFormatter(moment(new Date()));
    let data = {
        showProjectColumn: false,
        showPositionColumn: false,
        showPostedColumn: false,
        addedColumns: 0,
        data: []
    };
    json.resultList.map((line) => {
        let clockInDate = basicDateFormatter(moment(line.clockIn));
        let clockOutDate = line.clockOut ? basicDateFormatter(moment(line.clockOut)) : undefined;
        let date = multiDateFormat(line, clockInDate, clockOutDate);
        if (dates[date]) {
            dates[date].push(line);
        } else {
            dates[date] = [line];
        }
    });
    for (let date in dates) {
        const timeEvents = dates[date];
        let totalTime = 0;
        timeEvents.forEach((timeLog) => {
            if (timeLog.clockOut) {
                totalTime += projects ? timeLog.durationForProjects.totalDuration : timeLog.durationForPayroll;
            }
            if (timeLog.positionName) {
                data.showPositionColumn = true;
            }
            if (timeLog.projectName) {
                data.showProjectColumn = true;
            }
            if (timeLog.datePosted) {
                data.showPostedColumn = true;
            }
        });
        let line = {
            name: date,
            timeEvents: timeEvents,
            totalTime: totalTime
        };
        data.data.push(line);
    }
    if (data.showProjectColumn) {
        data.addedColumns += 1;
    }
    if (data.showPositionColumn) {
        data.addedColumns += 1;
    }
    if (data.showPostedColumn) {
        data.addedColumns += 1;
    }
    return data;
}
export function formatEmployeeTimeDataByEmployee(json) {
    let employees = mapArray('employeeCode', json.resultList);
    let data = {
        showProjectColumn: false,
        showPositionColumn: false,
        showPostedColumn: false,
        addedColumns: 0,
        data: []
    };

    for (let code in employees) {
        const timeEvents = employees[code];
        let totalTime = 0;
        timeEvents.forEach((event) => {
            if (event.clockOut) {
                totalTime += event.durationForPayroll;
            }
            if (event.positionName) {
                data.showPositionColumn = true;
            }
            if (event.projectName) {
                data.showProjectColumn = true;
            }
            if (event.datePosted) {
                data.showPostedColumn = true;
            }
        });
        let line = {
            name: timeEvents[0].employeeNameFirst + ' ' + timeEvents[0].employeeNameLast,
            timeEvents: timeEvents,
            totalTime: totalTime
        };
        data.data.push(line);
    }
    if (data.showProjectColumn) {
        data.addedColumns += 1;
    }
    if (data.showPositionColumn) {
        data.addedColumns += 1;
    }
    if (data.showPostedColumn) {
        data.addedColumns += 1;
    }
    return data;
}

export function formatGroupByEmployeePtoRequests(data) {
    let requests = [];
    // data.forEach((request) => {
    //     requests.push(this.mapArray('ptoRequestId', request.events));
    // });
    return requests;
}

export function combineMultiDayPtoRequests(json) {
    const requests = this.mapArray('ptoRequestId', json);
    const ptoObjectByName = formatPtoRequests(requests, this.mapArray);
    return {ptoObjectByName: ptoObjectByName, ptoObject: requests};
}

export function formatPtoRequests(requests, mapArray) {
    let ptoArray = [];
    for (let id in requests) {
        const requestedDays = requests[id];
        let totalHours = 0;
        let startDate = null;
        let endDate = null;
        let row = requestedDays[0];
        let name = requestedDays[0].name ? requestedDays[0].name : requestedDays[0].employeeNameFirst + ' ' + requestedDays[0].employeeNameLast;
        requestedDays.forEach((request) => {
            totalHours += Number(request.hours);
            if (!startDate) {
                startDate = request.startDate;
                endDate = request.startDate;
            } else if (request.startDate > endDate) {
                endDate = request.startDate
            }

            startDate = !startDate || request.startDate < startDate ? request.startDate : startDate;
            endDate = !endDate || request.endDate > endDate ? request.endDate : endDate;
        });
        let date = basicDateFormatter(moment(startDate), false, false);
        ptoArray.push({
            name: name,
            totalHours: totalHours,
            date: startDate === endDate ? date : date + ' - ' + basicDateFormatter(moment(endDate), false, false),
            row: row
        });
    }

    return mapArray('name', ptoArray);
}

export function formatBreakTimesForSerialization(breakObject, shouldDelete = true){
    if(shouldDelete){
        breakObject.deletedOn = formatDateToUtc(moment());
    }
    breakObject.breakIn = formatDateToUtc(breakObject.breakIn);
    breakObject.breakOut = formatDateToUtc(breakObject.breakOut);
    //if(breakObject.origin){
    //    breakObject.origin = breakObject.origin.toLowerCase();
    //}
    return breakObject;
}

export function formatShiftTimesForSerialization(shiftObject, shouldDelete = true){
    if(shouldDelete){
        shiftObject.deletedOn = formatDateToUtc(moment());
    }
    shiftObject.clockIn = formatDateToUtc(shiftObject.clockIn);
    shiftObject.clockOut = formatDateToUtc(shiftObject.clockOut);
    //shiftObject.origin = shiftObject.origin.toLowerCase();
    return shiftObject;
}

export function groupByEmployee(json, mapArray) {
    let requestsById = mapArray('ptoRequestId', json);
    let requestGroupByName = formatPtoRequests(requestsById, mapArray);
    let data = [];

    for (let employeeName in requestGroupByName) {
        const employeeGroup = requestGroupByName[employeeName];
        data.push(employeeGroup);
    }
    return {groupByEmployee: data, ptoObject: requestsById};
}

export function getTotalCost(logList) {
    if (logList.length !== 0) {
        logList.map
        const totalCost = logList.reduce((a, b) => {
            if (!a.totalCost) {
                return {totalCost: b.totalCost};
            }

            if (!b.totalCost) {
                return {totalCost: a.totalCost};
            }

            return {totalCost: a.totalCost + b.totalCost};
        });
        return formatCurrency(totalCost.totalCost, true)
    }
}

export function convertTimeToHours(milliseconds) {
    const time = milliseconds ? milliseconds : 0;
    if (JSON.parse(localStorage.companySettings).timeFormat === "HH:MM") {
        return formatTimer(time, false);
    } else {
        return (time / 3600).toFixed(2) + ' hrs';
    }

}

export function mapArray(key, array, value = false) {
    let map = {};
    array.map((object) => {
        if (map[object[key]]) {
            map[object[key]].push(value ? object[value] : object);
        } else {
            map[object[key]] = [value ? object[value] : object];
        }
    });
    return map;
}

export function organizePtoHours(ptoRequestList = null) {

    let totalHoursByPtoType = {
        sickHours: 0,
        vacationHours: 0,
        holidayHours: 0,
        totalPtoHours: 0

    };
    if (ptoRequestList) {
        for (const request in ptoRequestList) {
            if (ptoRequestList[request].type === 'Sick') {
                totalHoursByPtoType.sickHours += +ptoRequestList[request].hours;
            }
            else if (ptoRequestList[request].type === 'Vacation') {
                totalHoursByPtoType.vacationHours += +ptoRequestList[request].hours;
            }
            else if (ptoRequestList[request].type === 'Holiday') {
                totalHoursByPtoType.holidayHours += +ptoRequestList[request].hours;
            }
        }
        totalHoursByPtoType.sickHours = totalHoursByPtoType.sickHours * 3600;
        totalHoursByPtoType.vacationHours = totalHoursByPtoType.vacationHours * 3600;
        totalHoursByPtoType.holidayHours = totalHoursByPtoType.holidayHours * 3600;
        totalHoursByPtoType.totalPtoHours = totalHoursByPtoType.sickHours + totalHoursByPtoType.vacationHours + totalHoursByPtoType.holidayHours;
    }
    return totalHoursByPtoType;
}

export function generateRandomPin(pinLength){
    let newPin;

    if (pinLength === 4)
        newPin = Math.floor(1000 + Math.random() * 9000);
    else if (pinLength === 6)
        newPin = Math.floor(100000 + Math.random() * 900000);

    return newPin;
}